

  <template>
  <div class="userinfo-container">
    <section>
      <!--工具条-->
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true">
          <el-form-item>
            <el-button size="small" type="success" @click="create('')">创建</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <!--列表-->
      <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="key" border size='mini' :tree-props="{ children: 'children'}" :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }">
        <el-table-column prop="name" label="页面名称" sortable width="200">
        </el-table-column>
        <el-table-column prop="order" label="排序" sortable width="100">
        </el-table-column>
        <el-table-column prop="icon" label="图标" sortable width="250">
        </el-table-column>
        <el-table-column prop="url" label="组件地址"> </el-table-column>
        <el-table-column prop="webUrl" label="显示地址"> </el-table-column>
        <el-table-column prop="enabled" sortable label="是否启用" min-width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.enabled" @change="handleChange(scope.row)" active-color="#13ce66"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="create(scope.row.key)">添加</el-button>
            <el-button type="text" size="small" @click="update(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="remove(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--新增界面-->
      <div v-if="addFormVisible">
        <el-dialog v-dragDialog :title="textMap[dialogStatus]" :visible.sync="addFormVisible" width="500px" :close-on-click-modal="false">
          <el-form :model="addForm" ref="dataForm" label-width="130px" :rules="addFormRules">
            <el-form-item label="页面名称：" prop="name">
              <el-col :span="19">
                <el-input v-model="addForm.name" auto-complete="off" v-bind:readonly="readonlyUserName" placeholder="请输入页面名称"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="显示序号：" prop="order">
              <el-col :span="19">
                <el-input v-model.number="addForm.order" auto-complete="off" placeholder="请输入页面显示序号"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="图标样式：" prop="icon">
              <el-col :span="19">
                <el-input v-model="addForm.icon" auto-complete="off" placeholder="请输入图标样式"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="组件地址" prop="url">
              <el-col :span="19">
                <el-input v-model="addForm.url" auto-complete="off" placeholder="请输入导航地址"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="显示地址" prop="webUrl">
              <el-col :span="19">
                <el-input v-model="addForm.webUrl" auto-complete="off" placeholder="请输入显示地址"></el-input>
              </el-col>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="addFormVisible = false">取消</el-button>
            <el-button v-if="dialogStatus == 'create'" type="primary" @click="createSubmit">提交</el-button>
            <el-button v-else type="primary" @click="updateSubmit">提交</el-button>
          </div>
        </el-dialog>
      </div>
    </section>
  </div>
</template>
<script>
import { getAllPages, save, del, setPageStatus } from "@/api/setting/page";
export default {
  components: {},
  data () {
    return {
      tableData: [],
      dialogStatus: "",
      addFormVisible: false,
      textMap: {
        update: "修改",
        create: "新增",
      },
      addForm: {
        name: "",
        order: "",
        icon: "",
        url: "",
        webUrl: "",
      },
      addFormRules: {
        order: [
          { required: true, message: "请输入页面序号", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入页面名称", trigger: "change" },
        ],
        icon: [
          { required: true, message: "请输入图标样式", trigger: "change" },
        ],
        url: [{ required: true, message: "请输入组件地址", trigger: "change" }],
        webUrl: [
          { required: true, message: "请输入显示地址", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleChange: function (row) {
      let param = { key: row.key, status: row.enabled }
      setPageStatus(param).then((response) => {
        if (response) {
        }
      });
    },
    create (parent) {
      this.dialogStatus = "create";
      this.addFormVisible = true;
      this.addForm = {
        name: "",
        icon: "",
        order: "",
        url: "",
        webUrl: "",
        parent: parent,
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let para = Object.assign({}, this.addForm);
          save(para).then((response) => {
            if (response) {
              this.msg.successMsg("操作成功");
              this.addFormVisible = false;
              this.getTableData();
            }
          });
        }
      });
    },
    update (row) {
      this.addForm = Object.assign({}, row); // copy obj
      this.dialogStatus = "update";
      this.addFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    updateSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.addForm);
          save(data).then((response) => {
            if (response) {
              this.msg.successMsg("操作成功");
              this.addFormVisible = false;
              this.getTableData();
            }
          });
        }
      });
    },
    remove: function (row) {
      let pageName = row.name;
      this.$confirm("确认删除页面" + pageName + "吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          let data = { key: row.key };
          del(data).then((response) => {
            if (response) {
              this.msg.successMsg("操作成功");
              this.getTableData();
            }
          });
        })
        .catch(() => { });
    },
    getTableData: function () {
      let data = {};
      getAllPages(data).then((response) => {
        if (response) {
          this.tableData = response.data;
        }
      });
    },
  },
  mounted () {
    this.getTableData();
  },
};
</script>
<style lang='scss' scoped>
.userinfo-container {
  padding: 10px;
}
/deep/.el-dialog {
  margin-top: 10px !important;
}
</style>
