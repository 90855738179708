import request from '@/utils/request'

export function getAllPages(data) {
  return request({
    url: 'page/getAllPages',
    method: 'post',
    data
  })
}

export function setPageStatus(data) {
  return request({
    url: 'page/setPageStatus',
    method: 'post',
    data
  })
}

export function del(data) {
    return request({
      url: 'page/delete',
      method: 'post',
      data
    })
  }

  export function save(data) {
    return request({
      url: 'page/save',
      method: 'post',
      data
    })
  }


