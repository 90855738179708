var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "userinfo-container" }, [
    _c(
      "section",
      [
        _c(
          "el-col",
          {
            staticClass: "toolbar",
            staticStyle: { "padding-bottom": "0px" },
            attrs: { span: 24 }
          },
          [
            _c(
              "el-form",
              { attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "success" },
                        on: {
                          click: function($event) {
                            return _vm.create("")
                          }
                        }
                      },
                      [_vm._v("创建")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%", "margin-bottom": "20px" },
            attrs: {
              data: _vm.tableData,
              "row-key": "key",
              border: "",
              size: "mini",
              "tree-props": { children: "children" },
              "header-cell-style": {
                "font-weight": "400",
                height: "40px",
                padding: "0",
                background: "#4a8bc1",
                color: "white"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "页面名称",
                sortable: "",
                width: "200"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "order",
                label: "排序",
                sortable: "",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "icon", label: "图标", sortable: "", width: "250" }
            }),
            _c("el-table-column", {
              attrs: { prop: "url", label: "组件地址" }
            }),
            _c("el-table-column", {
              attrs: { prop: "webUrl", label: "显示地址" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "enabled",
                sortable: "",
                label: "是否启用",
                "min-width": "100"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        on: {
                          change: function($event) {
                            return _vm.handleChange(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.enabled,
                          callback: function($$v) {
                            _vm.$set(scope.row, "enabled", $$v)
                          },
                          expression: "scope.row.enabled"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.create(scope.row.key)
                            }
                          }
                        },
                        [_vm._v("添加")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.update(scope.row)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.remove(scope.row)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm.addFormVisible
          ? _c(
              "div",
              [
                _c(
                  "el-dialog",
                  {
                    directives: [
                      { name: "dragDialog", rawName: "v-dragDialog" }
                    ],
                    attrs: {
                      title: _vm.textMap[_vm.dialogStatus],
                      visible: _vm.addFormVisible,
                      width: "500px",
                      "close-on-click-modal": false
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.addFormVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "dataForm",
                        attrs: {
                          model: _vm.addForm,
                          "label-width": "130px",
                          rules: _vm.addFormRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "页面名称：", prop: "name" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    readonly: _vm.readonlyUserName,
                                    placeholder: "请输入页面名称"
                                  },
                                  model: {
                                    value: _vm.addForm.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "name", $$v)
                                    },
                                    expression: "addForm.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "显示序号：", prop: "order" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: "请输入页面显示序号"
                                  },
                                  model: {
                                    value: _vm.addForm.order,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "order",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "addForm.order"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "图标样式：", prop: "icon" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: "请输入图标样式"
                                  },
                                  model: {
                                    value: _vm.addForm.icon,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "icon", $$v)
                                    },
                                    expression: "addForm.icon"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "组件地址", prop: "url" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: "请输入导航地址"
                                  },
                                  model: {
                                    value: _vm.addForm.url,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "url", $$v)
                                    },
                                    expression: "addForm.url"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "显示地址", prop: "webUrl" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: "请输入显示地址"
                                  },
                                  model: {
                                    value: _vm.addForm.webUrl,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "webUrl", $$v)
                                    },
                                    expression: "addForm.webUrl"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.addFormVisible = false
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        _vm.dialogStatus == "create"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.createSubmit }
                              },
                              [_vm._v("提交")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.updateSubmit }
                              },
                              [_vm._v("提交")]
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }